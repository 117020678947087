.vertical-center {
  position: absolute;
  top: 10%;
  left: 0;
  bottom: 90%;
  right: 0;
  margin: auto;
  color: white;
}

#introduction-header {
  font-size: 58px;
}

#name-description {
  font-family: Optima;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}
.jumbotron {
  background-color: transparent;
}

.dl-horizontal {
  font-size: 30;
}

.name-header {
  font-weight: normal;
  color: #ffffff;
  word-spacing: 2pt;
  font-size: 58px;
  font-family: georgia, serif;
  text-align: center;
}

.hello-header {
  font-weight: normal;
  color: #000000;
  word-spacing: 2pt;
  font-size: 58px;
  font-family: georgia, serif;
  text-align: center;
}

.subject-header {
  text-align: center;
  font-weight: normal;
  color: #000000;
  word-spacing: 2pt;
  font-size: 70px;
  font-family: georgia, serif;
}

.company-name {
  font-weight: normal;
  color: #000000;
  letter-spacing: 1pt;
  word-spacing: 1pt;
  font-size: 50px;
  text-align: left;
  font-family: georgia, serif;
  line-height: 1;
}

.subject-sub-date {
  font-weight: normal;
  color: #000000;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 25px;
  font-family: georgia, serif;
  line-height: 1;
}

.small-date {
  font-weight: normal;
  color: #000000;
  font-size: 15px;
  font-family: georgia, serif;
  line-height: 1;
}
.job-title {
  font-weight: normal;
  color: #000000;
  word-spacing: 2pt;
  font-size: 40px;
  text-align: left;
  font-family: georgia, serif;
}

.isdr-job-title {
  font-weight: normal;
  color: #000000;
  word-spacing: 2pt;
  font-size: 30px;
  text-align: left;
  font-family: georgia, serif;
}

.job-description {
  font-size: 25px;
  color: #515151;
}

.job-team-name {
  font-weight: normal;
  color: #000000;
  font-size: 30px;
  text-align: left;
  font-family: georgia, serif;
}

.job-key-points {
  font-weight: normal;
  color: #000000;
  font-size: 20px;
  text-align: left;
  font-family: georgia, serif;
}

.top-buffer {
  margin-top: 50px;
}

.contact-info {
  font-size: 15px;
}

.about-me {
  font-size: 25px;
  font-weight: 300;
}

.parallax {
  /* The image used */
  background-image: url("../Images/Header/surf.jpg");

  /* Set a specific height */
  min-height: 1000px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Turn off parallax scrolling for tablets and phones.*/
@media only screen and (max-device-width: 1024px) {
  .parallax {
    display: None;
  }
}

body {
  margin: 0;
}

/* Carosel Styles */
.slick-prev:before {
  color: black;
}

.slick-next:before {
  color: black;
}

/* YouTube Div Container that makes the embeded video responsive */
.youtube-container {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.youtube-container iframe,
.youtube-container object,
.youtube-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.subject-header {
  font-weight: "normal";
  color: "#000000";
  word-spacing: "2pt";
  font-size: "70px";
  font-family: "georgia";
  text-align: "center";
}

.footer {
  float: right;
  margin-top: 25px;
}

/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 400;
  line-height: 1;
  letter-spacing: -0.05rem;
  font-size: 50px;
}

/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
